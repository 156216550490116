import { TFunction } from "i18next";
import { BannerBlockDto } from "../components/blocks/marketing/banner/BannerBlockUtils";

export function defaultBanner({ t }: { t: TFunction }): BannerBlockDto | undefined {
  return undefined;
  // return {
  //   style: "top",
  //   text: t("front.banner.text"),
  //   textMd: t("front.banner.textMd"),
  //   icon: "sale",
  //   cta: [{ text: t("front.banner.cta"), href: "/pricing?coupon=launch" }],
  // };
}
